<template>
    <div
        class="bg-login flex-column justify-center full-height"
    >
        <div
            class="bg-login-content "
        >
            <div
                class="pa-10 text-center bg-333"
            >
				<span
                    class="size-em-15 font-weight-bold vertical-middle"
                ><img src="@/assets/images/logo.png" style="width: 6rem"/></span>
            </div>
            <div v-if="!otp_info.requiredFlag">
                <div class="pa-10 mt-10">
                    <input
                        v-model="item.id"
                        class="input-underline full-width"
                        :class="{  input: isIdInput, 'error-underline': error.type == 'id' }"
                        type="text" placeholder="아이디를 입력하세요."
                        maxlength="15"
                        @keyup.enter="checkOtp"
                    />
                </div>
                <div class="pa-10 toggle-password">
                    <input
                        v-model="item.pw"
                        class="input-underline full-width"
                        :class="{ input: isPwInput, 'error-underline': error.type == 'pw'  }"
                        :type="toggleType"
                        placeholder="비밀번호를 입력하세요."
                        maxlength="50"
                        @keyup.enter="checkOtp"
                    />
                    <v-icon
                        v-if="toggleType == 'text'"
                        class="toggle"
                        :class="{ on: toggleType == 'text' }"
                        @click="toggleis_on_pw"
                    >mdi-eye
                    </v-icon>
                    <v-icon
                        v-else
                        class="toggle"
                        :class="{ on: toggleType == 'text' }"
                        @click="toggleis_on_pw"
                    >mdi-eye-off
                    </v-icon>
                </div>
            </div>
            <div v-else>
                <p class="mt-15 ml-15 size-px-12 font-weight-bold">관리자 2단계 인증</p>
                <div class="pa-10 toggle-password">
                    <input
                        v-model="item.otpCode"
                        class="input-underline full-width"
                        :class="{ input: isPwInput, 'error-underline': error.type == 'pw'  }"
                        :type="toggleType"
                        @input="item.otpCode = item.otpCode.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        placeholder="OTP 숫자 6자리"
                        maxlength="6"
                        @keyup.enter="login"
                    />
                    <v-icon
                        v-if="toggleType == 'text'"
                        class="toggle"
                        :class="{ on: toggleType == 'text' }"
                        @click="toggleis_on_pw"
                    >mdi-eye
                    </v-icon>
                    <v-icon
                        v-else
                        class="toggle"
                        :class="{ on: toggleType == 'text' }"
                        @click="toggleis_on_pw"
                    >mdi-eye-off
                    </v-icon>
                </div>
            </div>
            <div
                v-show="error.type"
                class="pa-10 color-red"
            >
                <v-icon small class="color-red">mdi-alert</v-icon>
                {{ error.msg }}
            </div>

            <div
                class="mt-10 pa-10"
            >
                <button
                    v-if="step === 1"
                    class="btn btn-identify"
                    @click="checkOtp"
                >다음
                </button>
                <button
                    v-else
                    class="btn btn-identify"
                    @click="login"
                >로그인
                </button>
            </div>

            <div class="pb-10 pr-10 justify-end" v-if="otp_info.registerFlag">
                <button class="color-identify font-weight-bold under-line-identify size-em-09" @click="showOtpSet">OTP 분실 및 재등록</button>
            </div>
            <div
                v-if="false"
                class="pa-10 mt-10 text-center login-menu justify-space-around"
            >
                <router-link :to="{ name: 'FindId' }">아이디 찾기</router-link>
                <router-link :to="{ name: 'FindPw' }">비밀번호 찾기</router-link>
            </div>

        </div>
        <OtpSet
            ref="otpSet"
            :item="item"
            :otp_info="otp_info"
            :layout="$layout"
            @success="setOtp"
            @close="closeOtp"
        />
    </div>
</template>

<script>

import OtpSet from "@/view/Auth/OtpSet.vue";

export default {
    name: 'Login'
    ,
    components: {OtpSet},
    data: function () {
        return {
            program: {
                name: '로그인'
                , code: 'login'
                , top: false
                , bottom: false
                , title: false
                , bland: false
                , wrap: 'loginNew'
            }
            , item: {
                id: ''
                , pw: ''
                , otpCode: ''
                , primaryToken: ''
            }
            , is_id_save: false
            , is_auto_login: false
            , is_setting_otp: false
            , error: {
                type: ''
                , msg: ''
            }
            , is_id_error: false
            , is_pw_error: false
            , is_on_pw: false
            , otp_info: {
                requiredFlag: false,
                registerFlag: false,
                primaryToken: '',
            }
            , step: 1
        }
    }
    , computed: {
        isIdInput: function () {
            return !!this.item.id;
        }
        , isPwInput: function () {
            return !!this.item.pw;
        }
        , toggleType: function () {
            if (this.is_on_pw) {
                return 'text'
            } else {
                return 'password'
            }
        }
    }
    , methods: {
        setOtp: function () {
            this.$layout.setNotify({type: 'success', message: 'OTP 등록이 완료되었습니다.'})
        },
        closeOtp: function () {
          this.is_setting_otp = false
          this.step = 1
        },
        checkOtp: async function () {
            console.log(this.otp_info, 'asdasd')
            let item = this.item
            try {
                this.$layout.onLoading()
                let result = await this.$Axios({
                    method: 'post'
                    , url: 'auth/check/otp'
                    , data: item
                    , authorize: true
                })
                if (result.success) {
                    this.otp_info = result.data
                    sessionStorage.setItem('otp', JSON.stringify(result.data))
                    if(this.otp_info.requiredFlag) {
                        this.step = 2
                        if (!this.otp_info.registerFlag) {
                           this.showOtpSet()
                        }
                    } else {
                        await this.login()
                    }
                    this.error = { type: '', msg: '' }
                } else {
                    if (result.message.indexOf('아이디') > -1) {
                        this.error.type = 'id'
                    } else {
                        this.error.type = 'pw'
                    }
                    this.error.msg = result.message
                    this.otp_info = {}
                }
            } catch (E) {
                console.log(E)
                this.$layout.setNotify({type: 'error', message: E})
            } finally {
                this.$layout.offLoading()
            }
        },
        showOtpSet(){
            this.is_setting_otp = true
            this.$refs.otpSet.show(this.otp_info)
        },
        login: async function () {
            try {
                let item = this.item
                let url
                this.$layout.onLoading()
                if (!this.otp_info.requiredFlag) {
                    url = 'auth/login'
                } else {
                    url = 'auth/login/otp'
                    item.primaryToken = this.otp_info.primaryToken
                }
                let result = await this.$Axios({
                    method: 'post'
                    , url: url
                    , data: item
                    , authorize: true
                })
                if (result.success) {
                    sessionStorage.setItem(process.env.VUE_APP_NAME + 'AT', result.data.accessToken)
                    sessionStorage.setItem(process.env.VUE_APP_NAME + 'RT', result.data.refreshToken)
                    this.error.type = ''
                    this.toMain(result.data.roleId)
                } else {

                    if (result.message.indexOf('아이디') > -1) {
                        this.error.type = 'id'
                    } else {
                        this.error.type = 'pw'
                    }
                    this.error.msg = result.message
                }
            } catch (E) {
                console.log(E)
                this.$layout.setNotify({type: 'error', message: E})
            } finally {
                this.$layout.offLoading()
            }
        }
        , toggleis_on_pw: function () {
            if (this.is_on_pw) {
                this.is_on_pw = false
            } else {
                this.is_on_pw = true
            }
        }
        , toMain: function (role) {
            if(role === 'shop_admin') {
                document.location.href = '/Merchant/List'
            } else {
                document.location.href = '/Settlement/Mypage'
            }
        }
        , doSample: function () {
            if (process.env.VUE_APP_TYPE == 'sample') {
                sessionStorage.setItem(process.env.VUE_APP_NAME + 'AT', process.env.VUE_APP_TYPE)
                this.toMain()
            } else {
                alert(process.env.VUE_APP_TYPE)
            }
        }
    }
    , created: function () {
        this.$layout = this.$root.$children[0].$children[0]
        this.$layout.onLoad(this.program)
        this.step = 1

    }
    , watch: {}
}
</script>

<style>

.bg-login {
    background-color: #eee;
    padding: 10px;
}

.bg-login-content {
    width: 320px;
    background-color: white;
    border-radius: 5px;
    margin: 0 auto
}

.auto-login {
    vertical-align: middle;
    font-size: 14px;
}

.login-menu {
}

.login-menu a {
    font-size: 14px;
    font-weight: 400
}

.toggle-password {
    position: relative;
}

.toggle-password .toggle {
    position: absolute;
    right: 10px;
    top: 20px;
    color: #bbb
}

.toggle-password .toggle.on {
    color: #0f6ecd
}

.error-underline {
    background: none;
    border-bottom: 1px solid red !important;
}

</style>