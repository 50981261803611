<template>
    <div
        v-if="is_show"
        class="position-fixed-full radius-20 z-index-layer"
    >
        <div class="bg-layer"></div>
        <div
            class="position-relative viewer pa-20 flex-column justify-center full-width full-height"
        >
            <div>
                <div
                    class="bg-login flex-column justify-center full-height"
                >
                    <div
                        class="bg-login-content "
                    >
                        <div
                            class="pa-10 text-center bg-333"
                        >
                            <span
                                class="size-em-10 vertical-middle color-white"
                            >{{ otp_info.registerFlag ? 'OTP 분실 및 재등록' : 'OTP 등록' }}</span>
                        </div>
                        <div v-if="otp_info.registerFlag">
                            <div
                                class="prl-10 mt-20"
                            >
                                <button
                                    class="btn btn-identify"
                                    @click="sendSMS"
                                >인증번호 요청
                                </button>
                            </div>
                            <div class="pa-10 toggle-password">
                                <input
                                    v-model="otp_item.smsCode"
                                    class="input-underline full-width"
                                    :class="{ input: isSmsInput, 'error-underline': error.type == 'sms'  }"
                                    :type="smsToggleType"
                                    @input="otp_item.smsCode = otp_item.smsCode.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    placeholder="인증번호 입력"
                                    maxlength="6"
                                />
                                <v-icon
                                    v-if="smsToggleType == 'text'"
                                    class="toggle"
                                    :class="{ on: smsToggleType == 'text' }"
                                    @click="toggle_on_sms"
                                >mdi-eye
                                </v-icon>
                                <v-icon
                                    v-else
                                    class="toggle"
                                    :class="{ on: smsToggleType == 'text' }"
                                    @click="toggle_on_sms"
                                >mdi-eye-off
                                </v-icon>
                            </div>
                        </div>
                        <div class="pa-10-20">
                            <h6>Google OTP 인증</h6>
                            <p>① Google Authenticator 앱에서 바코드를 스캔하세요.</p>
                            <div class="justify-center">
                                <qrcode-vue class="mt-20" v-if="otp_url" :value="otp_url" :size="size" level="H" render-as="svg" />
                            </div>
                        </div>
                        <div class="flex-row items-center">
                            <div class="pa-10 width-75">
                                <div class="box pa-10-20">
                                    <p class="color-primary font-weight-bold">{{ otp_key }}</p>
                                </div>
                            </div>
                            <div>
                                <button
                                    class="btn btn-identify"
                                    @click="copy"
                                >복사
                                </button>
                            </div>
                        </div>
                        <div class="pa-10-20">
                            <p>① Google OTP 인증</p>
                            <div class="ptb-10 toggle-password">
                                <input
                                    v-model="otp_item.otpCode"
                                    class="input-underline full-width"
                                    :class="{ input: isOtpInput, 'error-underline': error.type == 'otp'  }"
                                    :type="otpToggleType"
                                    @input="otp_item.otpCode = otp_item.otpCode.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    placeholder="OTP 숫자 6자리"
                                    maxlength="6"
                                    @keyup.enter="setOtp"
                                />
                                <v-icon
                                    v-if="otpToggleType == 'text'"
                                    class="toggle"
                                    :class="{ on: otpToggleType == 'text' }"
                                    @click="toggle_on_otp"
                                >mdi-eye
                                </v-icon>
                                <v-icon
                                    v-else
                                    class="toggle"
                                    :class="{ on: otpToggleType == 'text' }"
                                    @click="toggle_on_otp"
                                >mdi-eye-off
                                </v-icon>
                            </div>
                        </div>
                        <div
                            v-show="error.msg"
                            class="prl-10 color-red"
                        >
                            <v-icon small class="color-red">mdi-alert</v-icon>
                            {{ error.msg }}
                        </div>
                        <div
                            class="mt-10 pa-10"
                        >
                            <button
                                class="btn btn-identify"
                                @click="setOtp"
                            >확인
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
    name: 'OtpSet'
    , components: {QrcodeVue}
    , props: [
        'item',
        'layout'
    ]
    , data: function () {
        return {
            is_show: false
            , otp_item: {
                smsCode: ''
                , otpKey: ''
                , otpCode: ''
                , primaryToken: ''
            }
            , otp_info: {}
            , size: 120
            , otp_url: ''
            , otp_key: ''
            , is_auto_login: false
            , error: {
                type: ''
                , msg: ''
            }
            , is_id_error: false
            , is_pw_error: false
            , is_on_sms: false
            , is_on_otp: false
        }
    }
    , computed: {
        isSmsInput: function () {
            return !!this.otp_item.smsCode;
        }
        , isOtpInput: function () {
            return !!this.otp_item.otpCode;
        }
        , smsToggleType: function () {
            if (this.is_on_sms) {
                return 'text'
            } else {
                return 'password'
            }
        }
        , otpToggleType: function () {
            if (this.is_on_otp) {
                return 'text'
            } else {
                return 'password'
            }
        }
    }
    , methods: {
        setOtp:async function () {
            try {
                this.layout.onLoading()
                this.otp_item.otpKey = this.otp_key
                this.otp_item.primaryToken = this.otp_info.primaryToken
                let result = await this.$Axios({
                    method: this.otp_info.registerFlag ? 'put' : 'post'
                    , url: 'auth/otp'
                    , data: this.otp_item
                })
                if (result.success) {
                    console.log(result)
                    this.is_show = false
                    this.$emit('success')
                } else {
                    console.log(result.message)
                    if (result.message.indexOf('OTP') > -1) {
                        this.error.type = 'otp'
                    } else {
                        this.error.type = 'sms'
                    }
                    this.error.msg = result.message
                }
            } catch (E) {
                console.log(E)
                this.layout.setNotify({type: 'error', message: E})
            } finally {
                this.layout.offLoading()
            }
        },
        getOtp: async function () {
            try {
                this.layout.onLoading()
                let result = await this.$Axios({
                    method: 'get'
                    , url: 'auth/otp?primaryToken=' + this.otp_info.primaryToken
                })
                if (result.success) {
                    this.otp_url = result.data.otpUrl
                    this.otp_key = result.data.otpKey
                } else {
                    this.layout.setNotify({type: 'error', message: result.message})
                    this.$emit('closeOtp')
                }
            } catch (E) {
                console.log(E)
                this.layout.setNotify({type: 'error', message: E})
            } finally {
                this.layout.offLoading()
            }
        },
        sendSMS: async function () {
            try {
                this.layout.onLoading()
                let primaryToken = { primaryToken : this.otp_info.primaryToken }
                console.log(primaryToken)
                let result = await this.$Axios({
                    method: 'post'
                    , url: 'auth/otp/sms'
                    , data: primaryToken
                })
                if (result.success) {
                    console.log(result)
                } else {
                    this.error.msg = result.message
                }
            } catch (E) {
                console.log(E)
                this.layout.setNotify({type: 'error', message: E})
            } finally {
                this.layout.offLoading()
            }
        },
        toggle_on_sms: function () {
            this.is_on_sms = !this.is_on_sms;
        },
        toggle_on_otp: function () {
            this.is_on_otp = !this.is_on_otp;
        },
        copy() {
            let tempInput = document.createElement('input')
            tempInput.value = this.otp_key
            document.body.appendChild(tempInput)
            tempInput.select()
            document.execCommand('copy')
            document.body.removeChild(tempInput)

            this.layout.setNotify( {type: 'success', message: '설정키가 복사되었습니다.'})
        },
        show: function (otp) {
            this.otp_info = otp
            this.is_show = true
            this.getOtp()
        }
    }
    , created: function () {
        this.otp_info = JSON.parse(sessionStorage.getItem('otp'))

    }
    , watch: {}
}
</script>

<style scoped>

.bg-login {
    background-color: #eee;
    padding: 10px;
}

.bg-login-content {
    width: 320px;
    background-color: white;
    border-radius: 5px;
    margin: 0 auto
}

.auto-login {
    vertical-align: middle;
    font-size: 14px;
}

.login-menu {
}

.login-menu a {
    font-size: 14px;
    font-weight: 400
}

.toggle-password {
    position: relative;
}

.toggle-password .toggle {
    position: absolute;
    right: 10px;
    top: 20px;
    color: #bbb
}

.toggle-password .toggle.on {
    color: #0f6ecd
}

.error-underline {
    background: none;
    border-bottom: 1px solid red !important;
}

.z-index-layer {
    z-index: 22
}

.position-fixed-full {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.bg-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3
}

.position-relative {
    position: relative
}
</style>